import React from "react";
import { Tween, Reveal } from "react-gsap";
import Link from "../Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImagePremierInn from "../../images/Hotel-PremierInn.png";
import ImageVillageHotel from "../../images/Hotel-VillageHotel.png";
import ImageTheQueens from "../../images/Hotel-TheQueens.png";

const CardHotel = (props) => {
  const {
    name,
    description,
    distance,
    bed,
    drink,
    food,
    parking,
    link,
    id,
    reverse,
  } = props;
  let xPosImage = "-30px";
  if (reverse) xPosImage = "30px";
  let xPosText = "30px";
  if (reverse) xPosText = "-30px";

  let image = null;
  if (id === "PremierInn") {
    image = ImagePremierInn;
  } else if (id === "VillageHotel") {
    image = ImageVillageHotel;
  } else if (id === "TheQueens") {
    image = ImageTheQueens;
  }

  return (
    <Reveal>
      <div className="mb-5">
        <div
          className={`row ${
            reverse && "flex-row-reverse"
          } align-items-center justify-content-center py-0 py-md-5`}
        >
          <Tween
            from={{ opacity: 0, x: xPosImage }}
            to={{ opacity: 1, x: "0" }}
          >
            <div className="col-10 col-md-5">
              <img
                src={image}
                alt={name}
                className="rounded rounded-circle w-100"
              />
            </div>
          </Tween>
          <Tween from={{ x: xPosText }} to={{ opacity: 1, x: "0" }}>
            <div
              className={`col-12 col-md-7 brand-imagetext-negative-${
                reverse ? "right" : "left"
              }`}
            >
              <div className="brand-background-white p-4 p-md-5 rounded">
                {name && (
                  <h3 className="brand-font brand-responsive">{name}</h3>
                )}
                {distance && <p className="small">{distance}</p>}
                {description && <p>{description}</p>}

                <ul className="list-unstyled">
                  {bed && (
                    <li className="py-2">
                      <FontAwesomeIcon icon="bed" className="me-2" />
                      {bed}
                    </li>
                  )}
                  {drink && (
                    <li className="py-2">
                      <FontAwesomeIcon icon="beer" className="me-2" />
                      {drink}
                    </li>
                  )}
                  {food && (
                    <li className="py-2">
                      <FontAwesomeIcon icon="utensils" className="me-2" />
                      {food}
                    </li>
                  )}
                  {parking && (
                    <li className="py-2">
                      <FontAwesomeIcon icon="car" className="me-2" />
                      {parking}
                    </li>
                  )}
                </ul>
                <div className="text-end">
                  <Link button to={link} icon>
                    Website
                  </Link>
                </div>
              </div>
            </div>
          </Tween>
        </div>
      </div>
    </Reveal>
  );
};

export default CardHotel;
