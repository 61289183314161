import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import CardHotel from "../components/Cards/CardHotel";

// Markup
const Hotels = () => {
  const openGraph = {
    type: "website",
    title: "Hotels | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="Hotels" colour="black" />
      <section className="py-5 brand-background-black">
        <div className="container">
          <CardHotel
            id="PremierInn"
            name="Premier Inn, Headingley"
            description="In the heart of Headingley, the Premier Inn is close to the wedding venue as well as all of our favourite local cafes, bars and restaurants"
            distance="20min walk"
            link="https://www.premierinn.com/gb/en/hotels/england/west-yorkshire/leeds/leeds-headingley.html"
            bed="Close to venue"
            drink="Lots of pubs and bars"
            food="Lots of restaurants and cafes"
            parking="Free parking"
          />
          <CardHotel
            id="VillageHotel"
            name="Village Hotel, Leeds North"
            description="A nice hotel (and home to our gym!), it's good for kids as it has a leisure pool and onsite bar/restaurant"
            distance="10min taxi"
            link="https://www.village-hotels.co.uk/leeds-north"
            bed="Just outside Headingley centre"
            drink="On site bar"
            food="On site restaurant"
            parking="Free parking"
            reverse
          />
          <CardHotel
            id="TheQueens"
            name="The Queens Hotel"
            description="The most iconic hotel in Leeds City Centre, located just outside the train station. Great proximity to the entire city with shops, restaurants and bars to explore on your doorstep"
            distance="15min taxi"
            link="https://www.thequeensleeds.co.uk"
            bed="Leeds City Centre"
            drink="All the bars in Leeds"
            food="Dozens of restaurants"
            parking="Paid parking"
          />
        </div>
        <div className="row justify-content-center">
          <div className="col-10 col-md-9 text-center">
            <div className="ratio ratio-21x9">
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1vnYGjzsNPGm-0MXWlKAXDqIaJtltQ2E&ehbc=2E312F"
                width="100%"
                height="100%"
                className="rounded"
                title="Map"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Hotels;
